import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout/layout'

const VinoPage = () => (
  <Layout>
    <h1>Minuto 116 Tinto</h1>
    <h2>D.O. Manchuela</h2>
    <p>Vino tinto joven</p>
    <p>Vino muy elegante, elaborado con nuestras mejores uvas de nuestra finca los mocoticos.</p>
    <Link to="/vinos">Back</Link>
  </Layout>
)

export default VinoPage
